
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import QuotesActivitiesTimelineItem from "@/views/shared/quotes/QuotesActivities/QuotesActivitiesTimelineItem.vue";
interface IQuotesActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "quoteActivitiesView",
  components: { QuotesActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): IQuotesActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          DELETE_BULK_QUOTE_SUCCESS,
          DELETE_BULK_QUOTE_FAILURE,
          ADD_QUOTE_FAILURE,
          ADD_QUOTE_SUCCESS,
          UPDATE_QUOTE_FAILURE,
          UPDATE_QUOTE_SUCCESS,
          RATE_QUOTE_SUCCESS,
          BIND_QUOTE_SUCCESS,
          BIND_QUOTE_FAILURE,
          RATE_QUOTE_FAILURE,
          DELETE_RATINGS_SUCCESS,
          DELETE_RATINGS_FAILURE,
          PRINT_QUOTE_SUCCESS,
          PRINT_QUOTE_FAILURE,
          SAVE_QUOTE_SUCCESS,
          SAVE_QUOTE_FAILURE,
          DELETE_BULK_QUOTE_SUCCESS,
          DELETE_QUOTE_SUCCESS,
          DELETE_QUOTE_FAILURE,
          SCRUB_QUOTE_ADDRESS_SUCCESS,
          SCRUB_QUOTE_ADDRESS_FAILURE
          DELETE_BULK_QUOTE_FAILURE,
          RUN_CREDIT_INFORMATION_SUCCESS,
          RUN_CREDIT_INFORMATION_FAILURE,
          RUN_LOSS_REPORT_SUCCESS,
          RUN_LOSS_REPORT_FAILURE,
          CHECK_CREDIT_EXISTENCE_SUCCESS,
          CHECK_CREDIT_EXISTENCE_FAILURE,
          CHECK_LOSS_EXISTENCE_SUCCESS,
          CHECK_LOSS_EXISTENCE_FAILURE,
          CREDIT_OVERWRITE_SUCCESS,
          CREDIT_OVERWRITE_FAILURE,
          LOSS_OVERWRITE_SUCCESS,
          LOSS_OVERWRITE_FAILURE
          DELETE_QUOTE_SUCCESS,
          DELETE_QUOTE_FAILURE,
          SCRUB_QUOTE_ADDRESS_SUCCESS,
          SCRUB_QUOTE_ADDRESS_FAILURE,
          GET_QUOTE_TERRITORY_SUCCESS,
          GET_QUOTE_TERRITORY_FAILURE,
          COUNTY_VALIDATION_FAILURE,
          `.replace(/\s/g, ""),
        activityType: "QuoteActivity"
      };
    }
  }
});

<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div class="flex gap-4 items-center">
        <div v-html="formattedActivityText" class=""></div>
        <div
          v-if="ratingSelected"
          v-on:click="ratingSelectedViewExpanded = !ratingSelectedViewExpanded"
        >
          <font-awesome-icon
            :icon="[
              'fal',
              !ratingSelectedViewExpanded ? 'chevron-right' : 'chevron-down'
            ]"
            class="text-blue-700 hover:text-blue-400 mr-2"
          />
        </div>
        <div v-if="showTerritoryData" v-on:click="handleToggleTerritoryData">
          <font-awesome-icon
            :icon="[
              'fal',
              toggleTerritoryData ? 'chevron-down' : 'chevron-right'
            ]"
            class="text-blue-700 hover:text-blue-400 mr-2"
            v-if="hasValidTerritoryData"
          />
        </div>
        <span
          v-if="
            displayRatingActivity &&
              sortedData &&
              sortedData.length &&
              ratings.length
          "
          v-on:click="expandRatingView"
        >
          <font-awesome-icon
            :icon="[
              'fal',
              !ratingViewExpanded ? 'chevron-right' : 'chevron-down'
            ]"
            class="text-blue-700 hover:text-blue-400 mr-2"
          />
        </span>
      </div>
      <section v-if="ratingSelectedViewExpanded">
        <ratings-viewer
          :rating="selectedRating"
          :label="selectedRating._id"
          :disabled="true"
        />
      </section>
      <section v-if="ratingViewExpanded">
        <ratings-activity :ratings="sortedData" />
      </section>
      <section v-if="hasValidTerritoryData && toggleTerritoryData">
        <TerritoryLookupSelect
          :territories="data.territory ? [data.territory] : []"
          :value="data.territoryId"
          :key="data.territoryId"
          :disabled="true"
        />
      </section>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
import QuoteActivitiesMixin from "./QuoteActivitiesMixin.vue";
import { companiesData } from "@/helpers/companies";
import { omit, set } from "lodash";
import { companyLineOfBusiness } from "@/helpers/selectOptions";
import RatingsActivity from "../Components/RatingsActivity.vue";
import RatingsViewer from "../Components/RatingsViewer.vue";
import TerritoryLookupSelect from "@/views/shared/quotes/TerritoryLookupSelect.vue";
export default {
  components: {
    RatingsActivity,
    RatingsViewer,
    TerritoryLookupSelect
  },
  mixins: [ActivitiesTimelineMixin, QuoteActivitiesMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    quote: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      quoteUrl: "",
      ratingSelected: false,
      ratingSelectedViewExpanded: false,
      selectedRating: {}
    };
  },
  methods: {
    getCompany(companyNumber) {
      const companies = companiesData(true);
      const company = companies.find(
        company => company.companyNumber === companyNumber
      );
      return company;
    },
    async generateReadableSelectQuoteRating() {
      this.ratingSelected = true;
      this.selectedRating = this.activity.data.selectedRating;
      let contentHtml = `<p>{userName} selected a quote rating</p>`;

      if (this.selectedRating && Object.keys(this.selectedRating).length) {
        const company = this.getCompany(this.selectedRating.companyNumber);
        this.selectedRating = set(
          this.selectedRating,
          "companyData.companyName",
          company.companyName
        );
      } else {
        if (this.data.formName) {
          contentHtml = contentHtml + `<p>Form Name: ${this.data.formName}</p>`;
        }
        if (this.data.formType) {
          contentHtml = contentHtml + `<p>Form Type: ${this.data.formType}</p>`;
        }

        if (companyLineOfBusiness && companyLineOfBusiness.length) {
          const lineOfBusiness = companyLineOfBusiness.find(
            lineOfBusiness => lineOfBusiness.value === this.quote?.policyType
          );
          if (lineOfBusiness && Object.keys(lineOfBusiness).length) {
            contentHtml =
              contentHtml + `<p>Policy Type: ${lineOfBusiness.label}</p>`;
          }
        }

        this.data.companyNumbers.forEach(compNumber => {
          const company = this.getCompany(compNumber);
          contentHtml += `<p>Company ${company.companyNumber} - ${company.companyName}</p>`;
        });
      }
      this.setReadableUpdateProps(contentHtml);
    },
    async generateAddressScrubbingForQuoteActivity() {
      let contentHtml = `<p>{userName} scrubbed quote address | ${this.quoteUrl}</p>`;
      contentHtml += `<div class="text-gray-700 font-bold text-md mb-1">Address Scrubbing Status</div>`;
      const { address = {} } = this.data;
      if (address && Object.keys(address).length && address.error) {
        contentHtml += `<span class="inline-block bg-red-100 rounded px-5 py-1 text-sm font-semibold text-red-700">Failed</span>`;
      } else {
        contentHtml += `<span class="inline-block bg-green-100 rounded px-5 py-1 text-sm font-semibold text-green-700">Success</span>`;
      }
      this.setReadableUpdateProps(contentHtml);
    }
  },
  computed: {
    hasValidCreditInformation() {
      const { ratingValidations } = this.data;
      const { hasCreditRating } = ratingValidations;
      return hasCreditRating;
    },
    hasValidLossInformation() {
      const { ratingValidations } = this.data;
      const { hasValidLossInformation } = ratingValidations;
      return hasValidLossInformation;
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        this.quoteUrl = `<a href="/quotes/${this.activity.data.quoteId}" style="color: ${this.linkColor}" class="font-bold" target="_blank">View Quote</a>`;
        switch (this.activity.name) {
          case "ADD_QUOTE_SUCCESS": {
            let content = `created a new quote `;
            const fields = omit(this.data, ["quoteId"]);
            if (Object.keys(fields).length) {
              content += " with fields: <br />";
              for (const field in fields) {
                content += `<span class="block">${field}: ${fields[field]}</span>`;
              }
            }
            content += `${this.quoteUrl}`;

            this.generateReadableGeneric({
              content,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "GET_QUOTE_TERRITORY_SUCCESS": {
            const totalTerritories = this.data.territoryIds.length;

            const content = `looked up quote territories and got ${totalTerritories} ${
              totalTerritories > 0 ? "territories" : "territory"
            }.  |  ${this.quoteUrl}`;

            this.generateReadableGeneric({
              content,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "GET_QUOTE_TERRITORY_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to lookup quote territory. |  ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_QUOTE_SUCCESS":
            await this.generateReadableUpdateQuote();
            break;
          case "UPDATE_QUOTE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to update this quote. |  ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "RATE_QUOTE_SUCCESS": {
            const quoteId = this.activity.data.quoteId;
            this.generateReadableGeneric({
              content: `rated a <a href="/quotes/${quoteId}">quote</a>.`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "DELETE_RATINGS_SUCCESS": {
            const quoteId = this.activity.data.quoteId;
            this.generateReadableGeneric({
              content: `deleted rating for <a href="/quotes/${quoteId}" class="text-atlas-blue" target="_blank">quote</a>.`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "RATE_QUOTE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to rate quote. | ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_RATINGS_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to delete quote ratings. | ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "BIND_QUOTE_SUCCESS":
            this.generateReadableGeneric({
              content: `bounded this quote successfully. | <a href="/quotes/${this.activity.data.quoteId}/view" style="color: ${this.linkColor}" class="font-bold" target="_blank">View Quote</a>`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "COUNTY_VALIDATION_FAILURE": {
            const {
              county = "",
              city = "",
              zipCode = "",
              companyNumber = "",
              agentCode = ""
            } = this.data;
            this.generateReadableFailGeneric({
              content: `failed quote county validation. | ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted,
              extraData: {
                county,
                city,
                zipCode,
                companyNumber,
                agentCode
              }
            });
            break;
          }
          case "BIND_QUOTE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to bind quote. | ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "PRINT_QUOTE_SUCCESS":
            this.generateReadableGeneric({
              content: `printed this quote successfully. | ${this.quoteUrl}`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "PRINT_QUOTE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to print quote. | ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "SAVE_QUOTE_SUCCESS":
            this.generateReadableGeneric({
              content: `saved this quote successfully. | ${this.quoteUrl}`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "SAVE_QUOTE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to save quote. | ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "SELECT_QUOTE_RATING_SUCCESS":
            this.generateReadableSelectQuoteRating();
            break;
          case "SELECT_QUOTE_RATING_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to select quote rating.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_QUOTE_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted ${this.data.quoteIds.length} quotes `,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_QUOTE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to delete multiple  ${this.data.quoteIds.length} quotes`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_QUOTE_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted a quote | ${this.quoteUrl}`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_QUOTE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to delete quote  | ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "SCRUB_QUOTE_ADDRESS_SUCCESS":
            await this.generateAddressScrubbingForQuoteActivity();
            break;
          case "SCRUB_QUOTE_ADDRESS_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to scrub address | ${this.quoteUrl}`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "RUN_CREDIT_INFORMATION_SUCCESS":
            this.generateReadableGeneric({
              content: `run credit information`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "RUN_CREDIT_INFORMATION_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to run credit information.`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "RUN_LOSS_REPORT_SUCCESS":
            this.generateReadableGeneric({
              content: `run loss report`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "RUN_LOSS_REPORT_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to run loss report.`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "CHECK_CREDIT_EXISTENCE_SUCCESS": {
            let defaultStatement =
              "but did not get a valid credit information.";
            if (this.data && this.data.hasValidCreditInformation) {
              defaultStatement = "and got a valid credit information.";
            }
            this.generateReadableGeneric({
              content: `checked for an exisiting Credit Information ${defaultStatement}`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "CHECK_CREDIT_EXISTENCE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to check for existing credit information.`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "CHECK_LOSS_EXISTENCE_SUCCESS": {
            let defaultStatement = "but did not get a valid loss report.";
            if (this.data && this.data.hasValidLossInformation) {
              defaultStatement = `and got ${this.data.lossReport.lossInformation.length} losses.`;
            }
            this.generateReadableGeneric({
              content: `checked for existing loss report ${defaultStatement}`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "CHECK_LOSS_EXISTENCE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to check existing loss report.`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "CREDIT_OVERWRITE_SUCCESS":
            this.generateReadableGeneric({
              content: `overwrote a credit information.`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "CREDIT_OVERWRITE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to overwrite credit information.`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "LOSS_OVERWRITE_SUCCESS":
            this.generateReadableGeneric({
              content: `overwrote a loss report.`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "LOSS_OVERWRITE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to overwrite loss report.`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>
